<template>
  <v-btn  class="highlighted pa-6" @click="onClick" :disabled="disabled">{{title}}<slot></slot></v-btn>
</template>

<script>

export default {
    props: {
        title: String,
        color: String,
        disabled: Boolean
    },
    methods:{
        onClick(e){
            this.$emit("click", e);
        }
    }
};
</script>

<style scoped>
    .highlighted{ 
        background: linear-gradient(180deg, #F1B1F4 23.44%, #A8E7F9 61.98%, #EEF3E3 100%);
        border: 1px solid #210912;
        box-shadow: 0px 4px 0px #210912, inset 0px 8px 4px rgba(255, 255, 255, 0.25), inset 0px -5px 1px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
        font-family: Sequel85;
    }
</style>


