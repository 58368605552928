<template>
      <v-container>
  <div>
    <!-- <v-alert :type="error.type" dismissible @input="onClose" v-model="open">{{
      error.content
    }}</v-alert>
    {{ lastError }} -->
    <div v-if="feedback.type === 'error'">
        <v-alert :type="feedback.type" dismissible @input="onDismiss" :value="feedback.type !== 'none'">{{
      feedback.content
    }}</v-alert>
    <!-- <v-overlay :value="open" :absolute="true" @click="onDismiss">
        <v-row align="center" justify="center" class="text-center">
          <h1>{{feedback.type}}</h1>
          <h2>{{feedback.content}}</h2>
        </v-row>
    </v-overlay> -->
    </div>
  </div>
      </v-container>
</template>

<script>
export default {
  data() {
    return {
      open: true,
    };
  },
  methods: {
    
    updateState() {
      if (this.$store.getters.lastFeedback.type != "none") {
        console.log("je change la visibility")
        this.open = false;
        setTimeout(() => {console.log("heho"); this.updateVisibility(true)}, 200);
      }
    },
    updateVisibility(v){
      this.open = v;
    },
    // isError(error){
    //     return error.type === "none";
    // }
    async onDismiss(){
        this.$store.commit("dismiss");
        // this.updateState();
    }
  },
  computed: {
    
      feedback(){
          // this.updateState();
          return this.$store.getters.lastFeedback;
      }
  },
};
</script>
<style>

.v-alert__wrapper{
  /* overflow: hidden; */
  overflow-wrap: anywhere;
}
</style>