import Vue from "vue";
import Vuex from "vuex";
import feedback from "./feedback";
import contract from "./contract";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        numberToMint: 3,
        maxNumberToMint: 5       
    },
    mutations: {
        setNumberToMint(state, number) {
            state.numberToMint = number;
        },
        setMaxNumberToMint(state, payload){
            state.maxNumberToMint = payload;
        },
        increment(state) {
            if (state.numberToMint < state.maxNumberToMint) {
                state.numberToMint++;
            }
        },
        decrement(state) {
            if (state.numberToMint > 1) {
                state.numberToMint--;
            }
        },
    },

    actions: {
        setMaxNumberToMint({commit}, newMax){
            commit("setMaxNumberToMint", newMax);
        }
    },

    modules: {
        feedback,
        contract,
        user
    },
});
