const state = () => ({
    errors: [],
    lastError: 0,

    warnings: [],
    lastWarning: 0,

    successes: [],
    lastSuccess: 0,
});

// getters
const getters = {

    lastFeedback(state){

        if(state.errors.length > state.lastError){
            return {type: "error", content: state.errors[state.lastError]}
        }else if(state.warnings > state.lastWarning){
            return {type: "warning", content: state.warnings[state.lastWarning]}
        }else if(state.successes.length > state.lastSuccess){
            return {type: "success", content: state.successes[state.lastSuccess]}
        }else{
            return {type: "none", content: {}}
        }

    }

};

// mutations
const mutations = {
    addError(state, error) {
        state.errors.push(error);
        //state.lastError++;
    },

    addWarning(state, warning) {
        state.warnings.push(warning);
    },

    addSuccess(state, success) {
        state.successes.push(success);
    },

    dismiss(state){

        if(state.errors.length > state.lastError){
            state.lastError++;
        }else if(state.warnings > state.lastWarning){
            state.lastWarning++;
        }else if(state.successes.length > state.lastSuccess){
            state.lastSuccess++;
        }

    }
};

export default {
    state,
    getters,
    mutations,
};
