import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import vueAwesomeCountdown from 'vue-awesome-countdown'
import router from './router'

Vue.config.productionTip = false;


Vue.use(vueAwesomeCountdown, 'vac')

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
