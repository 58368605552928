<template>
  <v-app style="overflow: hidden">
    <!-- <div
      style="
        position: absolute;
        top: 0%;
        z-index: 500;
        width: 100%;
        height: 10vh;
        background-color: rgba(119, 208, 246, 1);
        display: flex;
        padding-left: 3%;
        align-items: center;
      "
    >
      <img
        src="./assets/logo-candy.png"
        alt=""
        style="z-index: 500; width: 170px; max-width: 100%"
        @click="$router.push('/')"
      />
    </div> -->
    <v-app-bar color="rgba(119, 208, 246, 1)" flat elevation="0" height="auto" width="100%"
      :max-height="mobile ? '90px' : '130px'">
      <v-app-bar-title class="py-1 py-sm-6">
        <a href="/">
          <img src="./assets/logo-candy.png" style="height: auto; max-width: 80%" />
        </a>
       
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-row justify="end" class="mr-10 align-center" v-if="!mobile">

          <a href="/statistics" class="mr-10 sequel-85">Statistics</a>


        <v-btn icon>
          <a href="https://discord.gg/N9X99jEM3b" target="_blank">
            <img src="@/assets/discord-dark.svg" alt="" />
          </a>
        </v-btn>

        <v-btn icon class="mx-5">
          <a href="https://twitter.com/candy_robbers?s=21&t=xT0ZbHy4Xd_ilS671Q-G7A" target="_blank">
            <img src="@/assets/twitter-dark.svg" alt="" style="max-width: 35px"/>
          </a>
        </v-btn>

        <v-btn icon>
          <a href="https://instagram.com/candyrobbers.io?utm_medium=copy_link" target="_blank">
            <img src="@/assets/instagram-dark.svg" alt=""/>
          </a>
        </v-btn>
      </v-row>
      <v-btn icon v-if="mobile" @click="drawer = true">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer absolute right v-model="drawer" color="rgba(119, 208, 246, 1)">
      <v-list nav class="sequel-45">
        <v-list-item>
          <v-list-item-title>
            <h3 class="sequel-85">CandyRobbers</h3>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav class="sequel-45">
        <a href="/statistics" class="mr-10 sequel-85">Statistics</a>
        <v-list-item class="nav-item" href="https://discord.gg/N9X99jEM3b" target="_blank">
          <v-list-item-icon><img src="@/assets/discord-dark.svg" alt="" /></v-list-item-icon>
          <v-list-item-title>Discord</v-list-item-title>
        </v-list-item>

        <v-list-item href="https://twitter.com/candy_robbers?s=21&t=xT0ZbHy4Xd_ilS671Q-G7A" target="_blank">
          <v-list-item-icon><img src="@/assets/twitter-dark.svg" alt="" /></v-list-item-icon>

          <v-list-item-title>Twitter</v-list-item-title>
        </v-list-item>

        <v-list-item href="https://instagram.com/candyrobbers.io?utm_medium=copy_link" target="_blank">
          <v-list-item-icon><img src="@/assets/instagram-dark.svg" alt="" /></v-list-item-icon>

          <v-list-item-title>Instagram</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <router-view />
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
};
</script>


<style lang="scss">
.nav-item {
  cursor: pointer;
}

@font-face {
  font-family: Sequel85;
  src: url("./assets/Sequel100Black-85.ttf") format("opentype");
}

@font-face {
  font-family: Sequel45;
  src: url("./assets/Sequel100Black-45.ttf") format("opentype");
}

.sequel-85 {
  font-family: Sequel85;
}

.sequel-45 {
  font-family: Sequel45;
}

a {
  color: black !important;
  text-decoration: none;
}
</style>
