<template>
  <v-main>
    <v-container class="pt-lg-16">
      <v-row align="center" justify="center">
        <v-container>
          <v-row justify="center" class="text-subtitle-1 text-lg-h4 text-center mt-3">
            <h2 class="sequel-85">
              CANDY  <br v-if="mobile" />
              ROBBERS
            </h2>
          </v-row>
          <v-row justify="center" class="text-center text-subtitle-2 text-lg-h6 mt-10">
            <v-col cols="12" lg="11" xl="8">
              <h2 class="sequel-85">
                Animated digital collectibles made for art collectors
              </h2>
            </v-col>
          </v-row>

          <v-row id="mint" justify="center" class="mb-5">
            <img style="max-height: 340px" src="./../assets/gold.gif" />
          </v-row>

          <v-row justify="center">
            <base-button @click="goToRobbery()"> <span class="sequel-85">ROB NOW</span> </base-button>
          </v-row>
        </v-container>
      </v-row>

      <mint-dialog />
      <feedback-display />
    </v-container>

    <v-container class="text-center mt-16">
      <v-row justify="center" class="text-h6 text-lg-h4">
        <h1 class="sequel-85">BREAKING NEWS</h1>
      </v-row>
      <v-row justify="center" class="text-subtitle-1 text-lg-h6 mt-10">
        <h2 class="sequel-85">
          “CANDY ROBBERS, <br />
          HEIST OF THE CENTURY”
        </h2>
      </v-row>
      <v-row justify="center" class="mt-10">
        <!-- <styled-countdown :timeTarget="target" /> -->
      </v-row>
      <v-row justify="center" class="mt-10">
        <img src="@/assets/candy-shop.png" alt="" style="max-width: 90%" />
      </v-row>
      <v-row justify="center" class="mt-10">
        <base-button class="text-subtitle-2" @click="openNewTab('https://opensea.io/collection/candyrobbers')"><span class="sequel-85">BUY ON OPENSEA</span>
        </base-button>
      </v-row>
    </v-container>

    <img src="./../assets/banner-main.png" style="
        margin-top: 50px;
        margin-bottom: 50px;
        min-width: 100vw;
        max-width: 100vw;
      " />
    <v-row align="center" justify="center" class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16 mt-16">
      <v-container style="
          background-color: white;
          border-radius: 12px;
          border: 1px solid #210912;
        " class="px-7 px-sm-16">
        <v-row justify="center" class="text-h5 text-lg-h2 text-center py-4">
          <span class="sequel-85"> CANDY MAKERS </span>
        </v-row>
        <v-row justify="center" class="text-subtitle-1 text-lg-h5 text-center">
          <span class="sequel-45"> Team work make the dream work. </span>
        </v-row>
        <v-row justify="center" class="pt-7 pb-7">
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/ghost.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">GHOST</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">CEO</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/adamthemuse" target="_blank"
                class="sequel-85 team-member font-weight-black">@Adam</a>
            </v-row>
          </v-col>
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/skeleton.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">SKELETON</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">MARKETING</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/david_105105" target="_blank"
                class="sequel-85 team-member font-weight-black">@David</a>
            </v-row>
          </v-col>
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/gold.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">GOLD</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">INVESTOR</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/silverus777" target="_blank"
                class="sequel-85 team-member font-weight-black">@GP</a>
            </v-row>
          </v-col>
          
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/fire-robber.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">FIRE ROBBER</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">ARTIST</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/Yuyopsd" target="_blank"
                class="sequel-85 team-member font-weight-black">@Yoyo</a>
            </v-row>
          </v-col>
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/greedy-robber.png" alt="" style="max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">GREEDY ROBBER</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">ANIMATOR</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/piuxpiupo1" target="_blank"
                class="sequel-85 team-member font-weight-black">@Piuxpiupo</a>
            </v-row>
          </v-col>
        
         
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/ginger.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">GINDER BREAD</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">COLLAB MANAGER</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/vex_codm" target="_blank"
                class="sequel-85 team-member font-weight-black">@Vex</a>
            </v-row>
          </v-col>
          <v-col cols="6" lg="3" class="mb-3">
            <v-row justify="center">
              <img src="@/assets/police-officer.png" alt="" style="border: 1px solid #210912; max-width: 90%" />
            </v-row>
            <v-row justify="center" class="text-h6 text-center">
              <span class="sequel-85">POLICE OFFICER</span>
            </v-row>
            <v-row justify="center" class="text-subitle-2 text-center">
              <span class="sequel-45">MODERATOR</span>
            </v-row>
            <v-row justify="center" class="text-subtitle-1 text-center font-weight-black">
              <a href="https://twitter.com/NFTswaino" target="_blank"
                class="sequel-85 team-member font-weight-black">@NFTswaino</a>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row align="center" justify="center" class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16 mt-16 mb-16">
      <v-container style="
          background-color: white;
          border-radius: 12px;
          border: 1px solid #210912;
        " class="px-7 px-sm-16">
        <v-row justify="center" class="mt-sm-13">
          <v-col cols="12" sm="6" class="">
            <v-row class="text-h6 text-sm-h5 mb-5 mt-3">
              <h2 class="sequel-85">THE ARTIST</h2>
            </v-row>
            <v-row class="sequel-45 mb-16">
              <p>
                The Art of Candy Robbers brings back childhood memories and
                candy features. Meet our colorful traits, meaningful
                references and playful style. The collection is algorithmically
                generated from +150 unique hand-drawn traits illustrated by Yuyo
                and animated by Piuxpiuo.
              </p>
              <p>
                We aim to create a powerful animated collection for NFTs and Web
                3 enthusiasts. We are developing a big community of art collectors and candy lovers! 
              </p>
              <p>
                Animating all Candy Robbers really brings them alive and match
                our vision with digital + physical events where we could all
                meet & have fun.
              </p>
            </v-row>
            <v-row class="mb-16">
              <base-button @click="goToAnchor('mint')">JOIN US</base-button>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="mb-10">
            <v-row justify="center" align="center">
              <img style="max-width: 90%" src="@/assets/artist.png" alt="" />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row align="center" justify="center" class="mx-2 mx-sm-10 px-sm-10 mx-lg-16 px-lg-16 mt-16 mb-10">
      <v-container style="
            background-color: white;
            border-radius: 12px;
            border: 1px solid #210912;
          " class="px-7 px-sm-16 mb-10">
        <v-row class="text-h3 text-lg-h2 my-3">
          <span class="sequel-85">FAQ</span>
        </v-row>

        <v-expansion-panels accordion multiple class="sequel-45">
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-black text-uppercase">
              What are Candy Robbers?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="font-weight-light">
              Candy Robbers are 5,000 animated digital collectibles that came to life due to their notorious history.
              They were known for robbing multiple candy stores and becoming part of the most wanted list.
              Although all things had to come to end when they chose to retire and walk along their own journeys…
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold text-uppercase">
              What blockchain Candy Robbers use?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="font-weight-light">
              Candy Robbers are stored as ERC-721 tokens on the Ethereum
              blockchain.
            </v-expansion-panel-content>
          </v-expansion-panel>
          
          
          
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold text-uppercase">
              How to purchase a Candy Robber?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="font-weight-light">
              To purchase one of our Candy Robbers, you must first create a crypto wallet.
               There are many ways to create a wallet but the most reliable one is Metamask. 
               You can then visit multiple marketplaces such as Opensea, x2y2 or gem (blur.io is not included in this list due to royalty issue). 
               Once you type in the search bar “Candy Robbers” you may then scroll through our entire collection and chose the one you would like to buy!
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-row>
  </v-main>
</template>

<script>
import "@crossmint/client-sdk-vanilla-ui";
// import ConnectButton from "../components/ConnectButton.vue";
// import MintPresale from "../components/MintPresale.vue";
import FeedbackDisplay from "../components/FeedbackDisplay.vue";
import MintDialog from "../components/MintDialog.vue";
// import MintPublicAddSub from "../components/MintPublicAddSub.vue";
// import StyledCountdown from "../components/StyledCountdown.vue";
import BaseButton from "../components/BaseButton.vue";

export default {
  components: {
    // ConnectButton,
    // MintPresale,
    FeedbackDisplay,
    MintDialog,
    // MintPublicAddSub,
    // StyledCountdown,
    BaseButton,
  },
  data() {
    return {
      target: 1655216831000,
      currentRobber: 0,
    };
  },

  methods: {
    goToAnchor(anchor) {
      document.getElementById(anchor).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    goToRobbery() {
      this.$router.push("/robbery");

    },
    getRobber() {
      console.log("@/assets/robber-" + this.currentRobber + ".gif");
      return "@/assets/robber-" + this.currentRobber + ".gif";
    },
    rob() {
      this.currentRobber = (this.currentRobber + 1) % 4;
    },
    openNewTab(tab) {
      window.open(tab);
    }
  },

  computed: {
    connected() {
      return this.$store.state.user.connected;
    },

    whitelisted() {
      return this.$store.state.user.whitelisted;
    },

    maxSupply() {
      return this.$store.state.contract.maxSupply;
    },

    nbMinted() {
      return this.$store.state.contract.nbMinted;
    },

    userMinted() {
      return this.$store.state.contract.userMinted;
    },
    robber() {
      return "/assets/robber-" + this.currentRobber + ".gif";
    },
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 400;
        case "md":
        case "lg":
        case "xl":
          return 500;
        default:
          return "auto";
      }
    },
    mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style>
.v-main {
  background: #99e0ff;
  padding-top: 20vh;
}

.team-member {
  color: #77d0f6 !important;
  text-decoration: none;
}

/* h1 {
  font-size: 75px;
  line-height: 91px;
  text-align: center;
}

@media screen and (max-width: 540px) {
  h1 {
    font-size: 45px;
    line-height: 55px;
  }
} */

.v-expansion-panel::before {
  box-shadow: none !important;
}

.carousel-container {
  background: linear-gradient(180deg,
      #f1b1f4 0%,
      #a8e7f9 49.48%,
      #eef3e3 100%) !important;
  border: 1px solid #210912 !important;
  border-radius: 12px !important;
}
</style>