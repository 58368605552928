import { BigNumber, ethers } from "ethers";
import router from "../router";

const state = () => ({
    contractAddress: "0x138Ee1B0F23dA7f2445Df399866134f2C8a9C661",
    contract: undefined,
    presalePrice: BigNumber.from(0),
    salePrice: BigNumber.from(0),
    maxNumberToMint: 3,
    mint: {
        minting: false,
        minted: false,
        error: false,
        number: 0,
    },
    saleStart: 0,
    nbMinted: 0,
    userMinted: false,
    maxSupply: 5000,
});

const mutations = {
    setContract(state, contract) {
        state.contract = contract;
    },

    setPresalePrice(state, presalePrice) {
        state.presalePrice = presalePrice;
    },

    setSalePrice(state, salePrice) {
        state.salePrice = salePrice;
    },

    setPresaleStart(state, payload) {
        state.presaleStart = payload;
    },
    setSaleStart(state, payload) {
        state.saleStart = payload;
    },
    setTotalSupply(state, payload) {
        state.totalSupply = payload;
    },
    startMinting(state) {
        state.mint = { ...state.mint, minting: true, minted: false };
    },
    minted(state) {
        state.mint = { ...state.mint, minting: false, minted: false };

        if (router.currentRoute.path.startsWith("/thank-you")) {
            state.mint = { ...state.mint, minting: false, minted: true };
        } else {
            router.push("/thank-you");
        }
    },

    setNbMinted(state, payload) {
        state.nbMinted = payload;
    },

    setMaxSupply(state, payload) {
        state.maxSupply = payload;
    },

    setUserMinted(state, payload) {
        state.userMinted = payload;
    },
};

const actions = {
    async setUpContract({ commit, state, rootState }) {
        const STANDARD_INTERFACE = [
            "function freemint() external",
            "function publicSaleMint(uint256 amount) external payable",
            "function price() public view returns(uint256)",
            "function presalePrice() public view returns(uint256)",
            "function maxSupply() public view returns(uint256)",
            "function saleStart() public view returns(uint256)",
            "function minted() public view returns(uint256)",
            "function freemintUsed(address) public view returns(bool)",
        ];

        try {
            const contract = new ethers.Contract(
                state.contractAddress,
                STANDARD_INTERFACE,
                rootState.user.signer
            );

            commit("setContract", contract);

            const userMinted = await contract.freemintUsed(rootState.user.address)

            commit("setUserMinted", userMinted);

            const salePrice = await contract.price();

            commit("setSalePrice", salePrice);

            const saleStart = await contract.saleStart();

            commit("setSaleStart", saleStart.add(60).mul(1000).toNumber());
        } catch (e) {
            commit(
                "addError",
                "Error while communicating with the blockchain. Please refresh the page."
            );

            console.error(e);
        }
    },

    async presaleMint({ commit, state, rootState }) {
        try {
            await state.contract.callStatic.presaleMint(
                rootState.numberToMint,
                rootState.user.userInfos.presale.max,
                rootState.user.userInfos.presale.signature,
                { value: state.presalePrice.mul(rootState.numberToMint) }
            );

            let tx = await state.contract.presaleMint(
                rootState.numberToMint,
                rootState.user.userInfos.presale.max,
                rootState.user.userInfos.presale.signature,
                { value: state.presalePrice.mul(rootState.numberToMint) }
            );

            commit("startMinting");
            await tx.wait();
            commit("minted");

            // commit(
            //     "addSuccess",
            //     `You successfully minted ${state.numberToMint} NFTs !`
            // );
        } catch (e) {
            if (e.error) {
                commit("addError", e.error.message);
            } else {
                commit("addError", e.message);
            }
        }
    },

    async saleMint({ commit, state, rootState }) {
        try {
            await state.contract.callStatic.publicSaleMint(
                rootState.numberToMint,
                {
                    value: state.salePrice.mul(rootState.numberToMint),
                }
            );

            let tx = await state.contract.publicSaleMint(
                rootState.numberToMint,
                {
                    value: state.salePrice.mul(rootState.numberToMint),
                }
            );

            commit("startMinting");
            await tx.wait();
            commit("minted");

            // commit(
            //     "addSuccess",
            //     `You successfully minted ${state.numberToMint} NFTs !`
            // );
        } catch (e) {
            if (e.error) {
                commit("addError", e.error.message);
            } else {
                commit("addError", e.message);
            }
        }
    },

    async freemint({ commit, state }) {
        try {
            await state.contract.callStatic.freemint();

            let tx = await state.contract.freemint();

            commit("startMinting");
            await tx.wait();
            commit("minted");

            // commit(
            //     "addSuccess",
            //     `You successfully minted ${state.numberToMint} NFTs !`
            // );
        } catch (e) {
            if (e.error) {
                commit("addError", e.error.message);
            } else {
                commit("addError", e.message);
            }
        }
    },
};

export default {
    state,
    mutations,
    actions,
};
