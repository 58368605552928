<template>
  <v-dialog v-model="open" style="box-shadow:none !important;">
    <v-container >
      <v-row justify="center" align="center" class="text-center dialog pa-10">
        <v-col>
          <div v-if="mintState.minting">
            <v-row justify="center" align="center" class="mt-5 text-h5 text-sm-h4 text-lg-h3">
              <h2 class="sequel-85"> MINTING ROBBERS </h2>
            </v-row>
          <v-row justify="center" align="center">
            <img style="max-height: 340px;" src="@/assets/Chocolate.gif" />
          </v-row>
            <v-row justify="center" align="center" class="mt-5">
              <h3 class="sequel-45">Transaction is in progress. This might take several minutes. </h3>
            </v-row>
          </div>
          <div v-if="mintState.minted">
             <v-row justify="center" align="center" class="mt-5">
              <h3 class="sequel-45">Successful mint!</h3>
            </v-row>
          <v-row justify="center" align="center" class="mt-5">
            <v-icon white x-large> mdi-checkbox-marked-circle </v-icon>
          </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    checkMinting() {
      console.log("Iwatch");
      if (this.mintState.minting || this.mintState.minted) {
        this.open = true;
      }
    },
  },
  computed: {
    mintState() {
      // return {minting: true, minted: false}
      return this.$store.state.contract.mint;
    },
  },
  watch: {
    mintState() {
      this.checkMinting();
    },
  },
  mounted() {
    this.checkMinting();
  },
};
</script>

<style>
@keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  80% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}


@keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
} 

.rotating {
  animation: rotate 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

.bouncing {
  animation: bounce 1s ease-in-out both infinite;
}

.dialog {
  background-color: white;
  color: #210912;
  text-align: center;
  border: 1px solid #210912;
  border-radius: 12px;
  
}

.dialog-wrapper {
  box-shadow: none;
}

.v-dialog {
  box-shadow: none !important;
}

</style>