import axios from "axios";
import { BigNumber, ethers } from "ethers";

const USE_DRENGR_WHITELIST = true;

async function fetchWhitelistInfos(type, address) {
    if (USE_DRENGR_WHITELIST) {
        const response = await axios.post("/.netlify/functions/whitelist", {
            address: address,
            type: type,
        });

        const data = response.data;

        if (data.whitelist) {
            return data;
        } else {
            return { whitelist: false };
        }
    } else {
        const response = await axios.get(`/${type}.json`);
        const data = response.data;

        if (data[address]) {
            return { ...data[address], whitelist: true }
        } else {
            return { whitelist: false }
        }
    }
}

const state = () => ({
    provider: undefined,
    signer: undefined,
    address: "",
    balance: BigNumber.from(1),
    connected: false,
    whitelisted: false,
    raffled: false,
    userInfos: {},
    nfts: {},
    registrationSuccess: false
});

const mutations = {
    setUser(state, payload) {
        if (payload.provider) {
            state.provider = payload.provider;
        }
        if (payload.signer) {
            state.signer = payload.signer;
        }
        if (payload.address) {
            state.address = payload.address;
        }

        state.connected = true;
    },

    setBalance(state, balance) {
        state.balance = balance;
    },

    setWhitelist(state, payload) {
        state.whitelisted = payload;
    },
    setRaffle(state, payload) {
        state.raffled = payload;
    },

    setUserInfos(state, payload) {
        state.userInfos = payload;
    },
    setNftVar(state, payload) {

        let newContent = { ...state.nfts[payload.id], [payload.var]: payload.val }

        state.nfts = { ...state.nfts, [payload.id]: newContent }

    },
    setNft(state, payload) {
        state.nfts = { ...state.nfts, [payload.id]: payload.content }
    },
    setRegistrationSuccess(state, payload) {

        state.registrationSuccess = payload;
    }
};

const actions = {
    async connect({ commit, dispatch }, modal) {
        const web3provider = await modal.connect();

        const provider = new ethers.providers.Web3Provider(web3provider);
        console.log(provider);
        const signer = await provider.getSigner();

        const address = await signer.getAddress();

        commit("setUser", { provider, signer, address });

        const balance = await signer.getBalance();

        commit("setBalance", balance);

        dispatch("connected");
    },

    async connected({ dispatch }) {
        // await Promise.all([
        //     // await dispatch("setUpContract"),
        //     //await dispatch("setUpWhitelist"),
        // ]);
        await dispatch("fetchOwnedNfts")
    },

    async fetchOwnedNfts({ commit, dispatch, state }) {

        let holders = (await axios.get("/holders.json")).data;

        if (holders[state.address]) {

            for (let id of holders[state.address]) {

                commit("setNft", { id: id, content: {} })

                await dispatch("fetchNftsInfos", id)
                await dispatch("fetchIsNftsSent", id)
            }
        }

    },

    async fetchNftsInfos({ commit }, id) {

        try {

            const nftMetadatas = await axios.get(`https://candyrobbers.mypinata.cloud/ipfs/QmYn4eoBuajiq9Kp2dmnJCw8oGsL45fWmVVdx1jCa12NYw/${id}`);

            let imageHash = nftMetadatas.data.image.replace("ipfs://", "");
            imageHash = imageHash.replace("https://candyrobbers.mypinata.cloud/ipfs/", "");

            commit("setNftVar", { id: id, var: "name", val: nftMetadatas.data.name });
            commit("setNftVar", { id: id, var: "image", val: `https://candyrobbers.mypinata.cloud/ipfs/${imageHash}` });

        } catch (e) {
            console.error(e)
        }

    },

    async fetchIsNftsSent({commit}, id) {
        try {
            let idSent = (await axios.post("/.netlify/functions/robbers", {
                id : id
            })).data;
            commit("setNftVar", { id: id, var: "sent", val: idSent.isSent });
        } catch (e) {
            console.log(e);
        }
        
        
    },

    async signSelected({ commit, state }) {

        let baseMessage = "By signing this message you agree to the general terms and services of CandyRobbers."
        baseMessage += "\n"
        baseMessage += "You agree to let the following Robbers (represented by their ID) attend the Robbery event"
        baseMessage += "\n"
        baseMessage += "\n"

        let ids = []

        for (let id of Object.keys(state.nfts)) {

            if (state.nfts[id].selected === true) {
                baseMessage += id + "\n";
                ids.push(id)
            }

        }

        let sig = await state.signer.signMessage(baseMessage);

        try {
            await axios.post("/.netlify/functions/rob", {
                address: state.address,
                ids: ids,
                sig: sig
            })

            commit("setRegistrationSuccess", true)

        } catch (e) {
            console.error(e)
        }


    },

    async setUpWhitelist({ commit, state }) {
        try {
            const data = await fetchWhitelistInfos("presale", state.address);

            if (data.whitelist) {
                commit("setWhitelist", true);
                commit("setUserInfos", {
                    ...state.userInfos,
                    presale: data,
                });
            } else {
                commit("setWhitelist", false);
                commit("setMaxNumberToMint", 5);
            }
        } catch (e) {
            commit(
                "addError",
                "Error while fetching whitelist. Please refresh the page."
            );
            console.error(e);
        }
    },

    async setUpRaffle({ commit, state }) {
        try {
            const data = await fetchWhitelistInfos("raffle", state.address);


            if (data.whitelist) {
                commit("setRaffle", true);
                commit("setUserInfos", {
                    ...state.userInfos,
                    raffle: data,
                });
            } else {
                commit("setRaffle", false);
            }
        } catch (e) {
            commit(
                "addError",
                "Error while fetching raffle. Please refresh the page."
            );
            console.error(e);
        }
    },
};

export default {
    state,
    mutations,
    actions,
};
